import { render, staticRenderFns } from "./DreiD-Konfigurator.vue?vue&type=template&id=0cbc13da&scoped=true&"
import script from "./DreiD-Konfigurator.vue?vue&type=script&lang=js&"
export * from "./DreiD-Konfigurator.vue?vue&type=script&lang=js&"
import style0 from "./DreiD-Konfigurator.vue?vue&type=style&index=0&id=0cbc13da&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cbc13da",
  null
  
)

export default component.exports