import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import gsap from 'gsap';

export default class Scene {
  constructor(rootEl, vueRoot, apartments) {
    this.root = rootEl;
    this.vueRoot = vueRoot;
    this.apartments = apartments;

    this.INTERSECTED = null;
    this.selected = null;
    this.modelIsLoaded = false;
    this.mouseDownModel = null;

    this.clock = new THREE.Clock();

    this.width = rootEl.clientWidth;
    this.height = rootEl.clientHeight;

    this.background = 0xffffff;
    this.mapping = {};

    this.canvas = document.createElement('canvas');

    this.init();
    this.update();
    this.bindEvents();
  }

  init() {
    this.initScene();
    this.initLights();
    this.initCamera();
    this.initRenderer();
    this.initControls();

    this.root.appendChild(this.canvas);
  }

  initScene() {
    this.scene = new THREE.Scene();

    this.raycaster = new THREE.Raycaster();
    this.mouse = new THREE.Vector2();
  }

  initLights() {
    const ambient = new THREE.AmbientLight(0xFFFFFF, 0.9);
    const point = new THREE.PointLight(0xCCCCCC, 0.1, 10);
    const directional = new THREE.DirectionalLight(0xFFFFFF, 0.5);

    this.scene.add(ambient);
    this.scene.add(point);
    this.scene.add(directional);
  }

  initCamera() {
    const aspect = this.width / this.height;

    this.camera = new THREE.PerspectiveCamera(
      45,
      aspect,
      1,
      20000,
    );

    this.camera.position.z = 8000;
    this.camera.position.x = 4000;
    this.camera.position.y = 4000;
    this.camera.aspect = aspect;
    this.camera.updateProjectionMatrix();
  }

  initRenderer() {
    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
    });
    this.renderer.setSize(this.width, this.height);
    // this.renderer.setClearColor(this.background, 1);

    this.canvas = this.renderer.domElement;
  }

  initControls() {
    this.controls = new OrbitControls(
      this.camera,
      this.canvas,
    );

    this.controls.minPolarAngle = 0;
    this.controls.maxPolarAngle = (Math.PI) / 2.2;

    this.controls.smooth = true;
    this.controls.smoothspeed = 0.95;
    this.controls.autoRotateSpeed = 2;
    this.controls.maxDistance = 10000;
    this.controls.minDistance = 1000;

    this.controls.update();
  }

  render() {
    this.camera.lookAt(this.scene.position);

    if (this.modelIsLoaded) {
      this.raycaster.setFromCamera(this.mouse, this.camera);

      // calculate objects intersecting the picking ray
      const intersects = this.raycaster.intersectObjects(this.scene.children[3].children, true);
      if (intersects.length > 0) {
        if (this.INTERSECTED !== intersects[0].object) {
          if (this.INTERSECTED) {
            this.INTERSECTED.material.color.setHex(this.INTERSECTED.currentHex);
            this.INTERSECTED.material.opacity = this.INTERSECTED.currentOpacity;
          }

          if (Object.prototype.hasOwnProperty.call(this.apartments, intersects[0].object.name)) {
            this.INTERSECTED = intersects[0].object;
            if (this.INTERSECTED !== this.mouseDownModel) {
              this.mouseDownModel = null;
            }
            this.INTERSECTED.currentHex = this.INTERSECTED.material.color.getHex();
            this.INTERSECTED.currentOpacity = this.INTERSECTED.material.opacity;
            this.INTERSECTED.material.color.setHex(0xDDA337);
            this.INTERSECTED.material.opacity = 1;
          }
        }
      } else {
        if (this.INTERSECTED) {
          this.INTERSECTED.material.color.setHex(this.INTERSECTED.currentHex);
          this.INTERSECTED.material.opacity = this.INTERSECTED.currentOpacity;
        }

        this.INTERSECTED = null;
      }
    }

    this.renderer.render(this.scene, this.camera);
  }

  update() {
    requestAnimationFrame(() => this.update());

    this.controls.update();

    const delta = this.clock.getDelta();

    if (this.modelIsLoaded) {
      for (const object of this.scene.children[3].children) {
        object.actionOut.getMixer()
          .update(delta);
        object.actionIn.getMixer()
          .update(delta);
      }
    }

    this.render();
  }

  bindEvents() {
    window.addEventListener('resize', () => this.onResize());
    this.onMouse = this.onMouse.bind(this);
    this.canvas.addEventListener('mousemove', this.onMouse, false);
    this.onMouseDown = this.onMouseDown.bind(this);
    this.canvas.addEventListener('pointerdown', this.onMouseDown, false);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.canvas.addEventListener('pointerup', this.onMouseUp, false);
  }

  onMouseDown(event) {
    event.preventDefault();
    if (this.INTERSECTED) {
      this.mouseDownModel = this.INTERSECTED;
    }
  }

  onMouseUp(event) {
    event.preventDefault();
    if (this.INTERSECTED) {
      if (this.INTERSECTED === this.mouseDownModel) {
        this.select(this.INTERSECTED.name);
        this.vueRoot.$emit('selectObject', this.INTERSECTED.name);
      }
    }
    this.mouseDownModel = null;
  }

  onMouse(event) {
    // calculate mouse position in normalized device coordinates
    // (-1 to +1) for both components
    // TODO: adapt for in window
    const rect = this.root.getBoundingClientRect();

    if (event.clientX > rect.left && event.clientX < rect.left + rect.width
      && event.clientY > rect.top && event.clientY < rect.top + rect.height
    ) {
      const x = Math.min(Math.max(event.clientX - rect.left, 0), rect.width);
      const y = Math.min(Math.max(event.clientY - rect.top, 0), rect.height);
      this.mouse.x = (x / rect.width) * 2 - 1;
      this.mouse.y = -(y / rect.height) * 2 + 1;
    }
  }

  onResize() {
    this.width = this.root.clientWidth;
    this.height = this.root.clientHeight;

    this.renderer.setSize(this.width, this.height);

    this.camera.aspect = this.width / this.height;
    this.camera.updateProjectionMatrix();
  }

  select(objectId) {
    if (this.modelIsLoaded) {
      const object = this.mapping[objectId];
      if (this.selected !== object) {
        if (this.selected) {
          this.selected.material.color.setHex('0x5F3641');
          this.selected.material.opacity = 1;
        }

        if (this.INTERSECTED) {
          this.INTERSECTED.currentHex = '0xDDA337';
          this.INTERSECTED.currentOpacity = 1;
        }

        this.selected = object;
        if (this.selected) {
          this.selected.material.color.setHex('0xDDA337');
          this.selected.material.opacity = 1;
        }

        /* this.controls.enabled = false;

        const controls = this.controls;
        const camera = this.camera;

        let tween_x = gsap.to(camera.position, {
          duration: 1,
          ease: 'none',
          x: object.position.x,
          onUpdate() {
            controls.update();
          },
          onComplete() {
            controls.enabled = true;
          }
        });

        tween_x.play();

        let tween_y = gsap.to(camera.position, {
          duration: 1,
          ease: 'none',
          y: object.position.y,
          onUpdate() {
            controls.update();
          },
          onComplete() {
            controls.enabled = true;
          }
        });

        tween_y.play();

        let tween_z = gsap.to(camera.position, {
          duration: 1,
          ease: 'none',
          z: object.position.z,
          onUpdate() {
            controls.update();
          },
          onComplete() {
            controls.enabled = true;
          }
        });

        tween_z.play(); */
      }
    }
  }

  toggleFocus(objectId) {
    if (this.modelIsLoaded) {
      for (const object of this.scene.children[3].children) {
        if (object.customId !== objectId && object.inFocus) {
          const action = object.actionOut;
          action.reset();
          action.clampWhenFinished = true;
          action.setLoop(THREE.LoopOnce);
          action.play();
          object.inFocus = false;
        }
        if (object.customId === objectId && !object.inFocus) {
          const action = object.actionIn;
          action.reset();
          action.clampWhenFinished = true;
          action.setLoop(THREE.LoopOnce);
          action.play();
          object.inFocus = true;
        }
      }
    }
  }

  setApartments(apartments) {
    this.apartments = apartments;
  }

  colorize() {
    if (!this.gltf) {
      return;
    }
    for (const obj3d of this.gltf.scene.children) {
      this.mapping[obj3d.name] = obj3d;
      obj3d.material = new THREE.MeshPhongMaterial();
      obj3d.material.depthWrite = true;
      obj3d.material.transparent = true;
      obj3d.castShadow = true;
      console.log(obj3d.name)
      if (Object.prototype.hasOwnProperty.call(this.apartments, obj3d.name)) {
        obj3d.material.color.setHex('0x5F3641');
        obj3d.material.opacity = 1;
        obj3d.inFocus = true;
        obj3d.renderOrder = -1;
      } else if (obj3d.name.substring(0,4) == "text"){
        obj3d.material.color.setHex('0x646464');
        obj3d.material.opacity = 1.0;
        obj3d.inFocus = false;
        obj3d.renderOrder = 1;

      } else {
        obj3d.material.color.setHex('0x646464');
        obj3d.material.opacity = 0.2;
        obj3d.inFocus = false;
        obj3d.renderOrder = 1;
      }
    }
  }

  loadModel(model) {
    this.loader = new GLTFLoader();

    this.loader.load(model, (gltf) => {
      this.gltf = gltf;
      this.scene.add(gltf.scene);
      this.modelIsLoaded = true;

      let i = 1;
      for (const obj3d of gltf.scene.children) {
        // console.log(gltf.scene.children);

        const initialPosition = obj3d.position.clone();
        const nextPosition = initialPosition.clone()
          .add(new THREE.Vector3(0, -900, 0));

        /* const positionKfTIn = new THREE.VectorKeyframeTrack('.position', [0, 0.5], nextPosition.toArray()
              .concat(initialPosition.toArray()), THREE.InterpolateSmooth);
            const positionKfTOut = new THREE.VectorKeyframeTrack('.position', [0, 0.5], initialPosition.toArray()
              .concat(nextPosition.toArray()), THREE.InterpolateSmooth); */

        const colorKFIn = new THREE.ColorKeyframeTrack('.material.color', [0, 0.5],
          [100 / 255, 100 / 255, 100 / 255, 95 / 255, 54 / 255, 65 / 255], THREE.InterpolateSmooth);
        const colorKFOut = new THREE.ColorKeyframeTrack('.material.color', [0, 0.5],
          [95 / 255, 54 / 255, 65 / 255, 100 / 255, 100 / 255, 100 / 255], THREE.InterpolateSmooth);

        const opacityKFIn = new THREE.NumberKeyframeTrack('.material.opacity', [0, 0.5], [0.2, 1]);
        const opacityKFOut = new THREE.NumberKeyframeTrack('.material.opacity', [0, 0.5], [1, 0.2]);

        const clipIn = new THREE.AnimationClip('Action', 0.5, [colorKFIn, opacityKFIn]);
        const clipOut = new THREE.AnimationClip('Action', 0.5, [colorKFOut, opacityKFOut]);
        const mixerIn = new THREE.AnimationMixer(obj3d);
        const mixerOut = new THREE.AnimationMixer(obj3d);
        const actionIn = mixerIn.clipAction(clipIn);
        const actionOut = mixerOut.clipAction(clipOut);

        obj3d.actionIn = actionIn;
        obj3d.actionOut = actionOut;
      }
      this.colorize();
    },
    // called while loading is progressing
    (xhr) => {
      console.log(`${xhr.loaded / xhr.total * 100}% loaded`);
    },
    // called when loading has errors
    (error) => {
      console.log(error.message);
    });
    this.colorize();
  }
}
