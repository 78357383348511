// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/fonts/Averta-Bold.otf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/fonts/Averta-Regular.otf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "@font-face{font-family:Averta-Bold;font-weight:700;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"opentype\")}@font-face{font-family:Averta-Regular;font-weight:700;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"opentype\")}.form-person .custom-select[data-v-0727b4f4],.form-person input[data-v-0727b4f4],.form-person textarea[data-v-0727b4f4]{background-color:#f0f0f0;border:none}.form-person .row>div[data-v-0727b4f4]{margin-bottom:1rem}.form-person .form-group[data-v-0727b4f4]{display:flex;flex-direction:column;height:100%;justify-content:flex-end}.upload-area[data-v-0727b4f4]{background-color:#f0f0f0;height:200px;width:100%;display:flex;justify-content:center;align-items:center}.upload-area[data-v-0727b4f4],.upload-area[data-v-0727b4f4] label{cursor:pointer}.upload-area[data-v-0727b4f4]:hover{background-color:#d1d1d1}.files-added[data-v-0727b4f4]{background-color:#aa716a}.check[data-v-0727b4f4]{width:20px;margin-right:5px}.file-list[data-v-0727b4f4]{list-style:none;margin:0;padding:0;color:#fff}.remove-files[data-v-0727b4f4]{cursor:pointer}", ""]);
// Exports
module.exports = exports;
