const getFloor = (floor) => {
  if (floor === 0) {
    return 'EG';
  }

  return `${floor}. OG`;
};

const getCategoryName = (name) => {
  const catToName = {
    bathroom: 'Bad',
    kitchen: 'Küche',
    balcony: 'Balkon',
    garden: 'Garten',
    floorPlan: 'Grundriss',
    patio: 'Terasse',
    stairs: 'Treppenhaus',
    bedroom: 'Schlafzimmer',
    livingroom: 'Wohnzimmer',
    office: 'Büro',
    garage: 'Garage',
    entrance: 'Eingang',
    hero: 'Hauptbild',
  };

  const cat = catToName[name];
  return cat[0].toUpperCase() + cat.slice(1);
};

const getStatus = (state) => (state === 'free' ? 'Frei' : (state === 'reserved' ? 'Reserviert' : 'Vermietet'));

export { getFloor, getStatus, getCategoryName };
