<template>
  <div class="form-person">
    <b-row>
      <b-col md="12">
        <h2 class="text-dark-brown mb-2">Personalien</h2>
      </b-col>
      <b-col md="12" v-if="isFirstPerson == false">
      <b-form-group
          :id="'input-group-31-'.concat(personIndex)"
          label="Solidarhafter:"
          :label-for="'input-31-'.concat(personIndex)"
          style="display: block; height: auto"
      >
        <b-checkbox
            :id="'input-31-'.concat(personIndex)"
            v-model="value.solidarhafter"
            type="checkbox"
        />
      </b-form-group>

      <b-form-group
          style="display: block; height: auto"
          :id="'input-group-32-'.concat(personIndex)"
          label="Beziehung zu Mieter 1:"
          :label-for="'input-32-'.concat(personIndex)"
      >
        <select :id="'input-32-'.concat(personIndex)" v-model="value.relation">
          <option value="Lebenspartner">Lebenspartner</option>
          <option value="Ehepartner">Ehepartner</option>
          <option value="Elternteil">Elternteil</option>
          <option value="Kind">Kind</option>
          <option value="Geschwister">Geschwister</option>
          <option value="Befreundet">Befreundet</option>
          <option value="Andere">Andere</option>
        </select>
      </b-form-group>
      </b-col>

      <b-col cols="12" md="4" lg="4">
        <b-form-group
          :id="'input-group-1-'.concat(personIndex)"
          label="* Anrede:"
          :label-for="'input-1-'.concat(personIndex)"
        >
          <b-form-select
            :id="'input-1-'.concat(personIndex)"
            v-model="value.title"
            required
          >
            <option value="" selected disabled>Bitte auswählen</option>
            <option value="Herr">Herr</option>
            <option value="Frau">Frau</option>
            <option value="Divers">Divers</option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4" lg="4">
        <b-form-group
          :id="'input-group-3-'.concat(personIndex)"
          label="* Vorname:"
          :label-for="'input-3-'.concat(personIndex)"
        >
          <b-form-input
            :id="'input-3-'.concat(personIndex)"
            v-model="value.firstname"
            type="text"
            placeholder="Vorname"
            required
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4" lg="4">
        <b-form-group
          :id="'input-group-2-'.concat(personIndex)"
          label="* Name:"
          :label-for="'input-2-'.concat(personIndex)"
        >
          <b-form-input
            :id="'input-2-'.concat(personIndex)"
            v-model="value.lastname"
            placeholder="Nachname"
            type="text"
            required
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4" lg="4">
        <b-form-group
          :id="'input-group-4-'.concat(personIndex)"
          label="* Geburtsdatum:"
          :label-for="'input-4-'.concat(personIndex)"
        >
          <b-form-input
            :id="'input-4-'.concat(personIndex)"
            v-model="value.dob"
            type="date"
            placeholder="Geburtsdatum"
            required
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4" lg="4">
        <b-form-group
          :id="'input-group-7-'.concat(personIndex)"
          label="* Zivilstand:"
          :label-for="'input-7-'.concat(personIndex)"
        >
          <b-form-select
            :id="'input-7-'.concat(personIndex)"
            v-model="value.civilstate"
            required
          >
            <option value="" selected disabled>Bitte auswählen</option>
            <option value="Ledig">Ledig</option>
            <option value="Verheiratet / eingetragene Partnerschaft">Verheiratet / eingetragene Partnerschaft</option>
            <option value="Sonstig">Sonstig</option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6">
        <b-form-group
          :id="'input-group-5-'.concat(personIndex)"
          label="* Strasse / Nummer:"
          :label-for="'input-5-'.concat(personIndex)"
        >
          <b-form-input
            :id="'input-5-'.concat(personIndex)"
            v-model="value.currentAddress"
            type="text"
            placeholder="Aktuelle Addresse"
            required
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6">
        <b-form-group
          :id="'input-group-6-'.concat(personIndex)"
          label="* PLZ / Ort:"
          :label-for="'input-6-'.concat(personIndex)"
        >
          <b-form-input
            :id="'input-6-'.concat(personIndex)"
            v-model="value.currentPlace"
            type="text"
            placeholder="PLZ und Ort"
            required
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4">
        <b-form-group
          :id="'input-group-11-'.concat(personIndex)"
          label="Email:"
          :label-for="'input-11-'.concat(personIndex)"
        >
          <b-form-input
            :id="'input-11-'.concat(personIndex)"
            v-model="value.email"
            type="email"
            placeholder="E-Mail"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="4">
        <b-form-group
          :id="'input-group-9-'.concat(personIndex)"
          label="Tel. Mobile:"
          :label-for="'input-9-'.concat(personIndex)"
        >
          <b-form-input
            :id="'input-9-'.concat(personIndex)"
            v-model="value.phoneMobile"
            type="text"
            placeholder="Telefonnummer Mobile"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="4"> </b-col>

      <b-col cols="12" md="4">
        <b-form-group
          :id="'input-group-8-'.concat(personIndex)"
          label="* Tel. Privat:"
          :label-for="'input-8-'.concat(personIndex)"
        >
          <b-form-input
            :id="'input-8-'.concat(personIndex)"
            v-model="value.phonePrivate"
            type="text"
            placeholder="Telefonnummer"
            required
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4">
        <b-form-group
          :id="'input-group-10-'.concat(personIndex)"
          label="Tel. Geschäft:"
          :label-for="'input-10-'.concat(personIndex)"
        >
          <b-form-input
            :id="'input-10-'.concat(personIndex)"
            v-model="value.phoneWork"
            type="text"
            placeholder="Telefonnummer Geschäft"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4"> </b-col>
      <b-col cols="12" md="4">
        <b-form-group
          :id="'input-group-36-'.concat(personIndex)"
          label="* Nationalität:"
          :label-for="'input-36-'.concat(personIndex)"
        >
          <b-form-select
            :id="'input-36-'.concat(personIndex)"
            v-model="value.nationality"
            required
          >
            <option value="" selected disabled>Bitte auswählen</option>
            <option v-for="n in nationalities" :key="n" :value="n">{{ n }}</option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4">
        <b-form-group
          v-if="value.nationality != 'Schweiz'"
          :id="'input-group-12-'.concat(personIndex)"
          label="* Aufenthaltsbewilligung:"
          :label-for="'input-12-'.concat(personIndex)"
        >
          <b-form-select
            :id="'input-12-'.concat(personIndex)"
            v-model="value.residencepermit"
            required
          >
            <option value="" selected disabled>Bitte auswählen</option>
            <option value="B">B</option>
            <option value="C">C</option>
            <option value="F">F</option>
            <option value="G">G</option>
            <option value="L">L</option>
            <option value="N">N</option>
            <option value="Keine Bewilligung">Keine Bewilligung</option>
            <option value="Andere">Andere</option>
          </b-form-select>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="4">
        <b-form-group
          :id="'input-group-13-'.concat(personIndex)"
          label="* Heimatort:"
          :label-for="'input-13-'.concat(personIndex)"
        >
          <b-form-input
            :id="'input-13-'.concat(personIndex)"
            v-model="value.hometown"
            type="text"
            placeholder="Heimatort"
            required
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4">
        <b-form-group
          :id="'input-group-14-'.concat(personIndex)"
          label="Raucher:"
          :label-for="'input-14-'.concat(personIndex)"
        >
          <b-form-select
            :id="'input-14-'.concat(personIndex)"
            v-model="value.smoker"
          >
            <option :value="true">Ja</option>
            <option :value="false">Nein</option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4">
        <b-form-group
            :id="'input-group-15-'.concat(personIndex)"
            label="* Beschäftigungstyp:"
            :label-for="'input-15-'.concat(personIndex)"
        >
          <b-form-select
              :id="'input-15-'.concat(personIndex)"
              v-model="value.job"
              required
          >
            <option value="" selected disabled>Bitte auswählen</option>
            <option value="UNEMPLOYED">Nicht Erwerbstätig</option>
            <option value="EMPLOYED">Angestellt</option>
            <option value="SELF_EMPLOYED">Selbstständig</option>
            <option value="RETIRED">Rentner</option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4">
        <b-form-group
            v-if="value.job == 'EMPLOYED' || value.job == 'SELF_EMPLOYED'"
            :id="'input-group-16-'.concat(personIndex)"
            label="* Beruf:"
            :label-for="'input-16-'.concat(personIndex)"
        >
          <b-form-input
            :id="'input-16-'.concat(personIndex)"
            v-model="value.jobDescription"
            type="text"
            required
            placeholder="Beruf"
          />
        </b-form-group>
      </b-col>

      <b-col md="4"></b-col>

      <b-col cols="12" md="4">
        <b-form-group
          v-if="value.job == 'EMPLOYED' || value.job == 'SELF_EMPLOYED'"
          :id="'input-group-16-'.concat(personIndex)"
          label="* Arbeitgeber:"
          :label-for="'input-16-'.concat(personIndex)"
        >
          <b-form-input
            :id="'input-16-'.concat(personIndex)"
            v-model="value.employer"
            type="text"
            placeholder="Arbeitgeber"
            required
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="4">
        <b-form-group
          v-if="value.job == 'EMPLOYED' || value.job == 'SELF_EMPLOYED'"
          :id="'input-group-17-'.concat(personIndex)"
          label="* Arbeitgeber Ort:"
          :label-for="'input-17-'.concat(personIndex)"
        >
          <b-form-input
            :id="'input-17-'.concat(personIndex)"
            v-model="value.employerPlace"
            type="text"
            required
            placeholder="Arbeitgeber Ort"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="4">
        <b-form-group
          v-if="value.job == 'EMPLOYED' || value.job == 'SELF_EMPLOYED'"
          :id="'input-group-18-'.concat(personIndex)"
          label="In Anstellung seit:"
          :label-for="'input-18-'.concat(personIndex)"
        >
          <b-form-input
            :id="'input-18-'.concat(personIndex)"
            v-model="value.workingSince"
            type="date"
            placeholder="In Anstellung seit"
          />
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group
          v-if="value.job == 'EMPLOYED' || value.job == 'SELF_EMPLOYED'"
          :id="'input-group-19-'.concat(personIndex)"
          label="* Name Vorgesetzter:"
          :label-for="'input-19-'.concat(personIndex)"
        >
          <b-form-input
            :id="'input-19-'.concat(personIndex)"
            v-model="value.supervisorName"
            type="text"
            placeholder="Name Vorgesetzter"
            required
          />
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group
          v-if="value.job == 'EMPLOYED' || value.job == 'SELF_EMPLOYED'"
          :id="'input-group-20-'.concat(personIndex)"
          label="* Telefon Vorgesetzter:"
          :label-for="'input-20-'.concat(personIndex)"
        >
          <b-form-input
            :id="'input-20-'.concat(personIndex)"
            v-model="value.supervisorPhone"
            type="text"
            placeholder="Telefon Vorgesetzter"
            required
          />
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group
          v-if="value.job == 'EMPLOYED' || value.job == 'SELF_EMPLOYED'"
          :id="'input-group-99-'.concat(personIndex)"
          label="* Email Vorgesetzter:"
          :label-for="'input-99-'.concat(personIndex)"
        >
          <b-form-input
            :id="'input-99-'.concat(personIndex)"
            v-model="value.supervisorEmail"
            type="email"
            placeholder="Email Vorgsetzter"
            required
          />
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group
          v-if="value.job == 'EMPLOYED' || value.job == 'SELF_EMPLOYED'"
          :id="'input-group-21-'.concat(personIndex)"
          label="* Einkommen (Netto-Jahreseinkommen):"
          :label-for="'input-21-'.concat(personIndex)"
        >
          <b-form-input
            :id="'input-21-'.concat(personIndex)"
            v-model.number="value.income"
            type="number"
            placeholder="Einkommen (Netto-Jahreseinkommen)"
            pattern="[0-9]"
            required
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="12">
        <h2 class="text-dark-brown mb-2">Angaben zur aktuellen Wohnung</h2>
      </b-col>

      <b-col cols="12" md="12" v-if="!isFirstPerson">
        <b-form-group
          :id="'input-group-same-'.concat(personIndex)"
          label="Vom Hauptmieter übernehmen"
          :label-for="'input-same-'.concat(personIndex)"
        >
          <b-checkbox
            :id="'input-same-'.concat(personIndex)"
            v-model="value.sameVerwaltung"
            type="checkbox"
            @change="copyApartment"
          />
        </b-form-group>

      </b-col>

      <template v-if="!value.sameVerwaltung">
      <b-col md="4">
        <b-form-group
          :id="'input-group-22-'.concat(personIndex)"
          label="* Hauseigentümer / Verwaltung"
          :label-for="'input-22-'.concat(personIndex)"
        >
          <b-form-input
            :id="'input-22-'.concat(personIndex)"
            v-model="value.actualAdministration"
            type="text"
            placeholder="Hauseigentümer / Verwaltung"
            required
          />
        </b-form-group>
      </b-col>

       <b-col md="4">
        <b-form-group
          :id="'input-group-50-'.concat(personIndex)"
          label="* Verwaltung Email"
          :label-for="'input-50-'.concat(personIndex)"
        >
          <b-form-input
            :id="'input-50-'.concat(personIndex)"
            v-model="value.currentLandlordEmail"
            type="email"
            placeholder="Verwaltung Email"
            required
          />
        </b-form-group>
      </b-col>


      <b-col md="4">
        <b-form-group
          :id="'input-group-23-'.concat(personIndex)"
          label="* Wohnhaft seit"
          :label-for="'input-23-'.concat(personIndex)"
        >
          <b-form-input
            :id="'input-23-'.concat(personIndex)"
            v-model="value.actualLivingTime"
            type="date"
            placeholder="Wohnhaft seit"
            required
          />
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group
          :id="'input-group-24-'.concat(personIndex)"
          label="* Monatsmiete inkl. Nebenkosten"
          :label-for="'input-24-'.concat(personIndex)"
        >
          <b-form-input
            :id="'input-24-'.concat(personIndex)"
            v-model.number="value.actualMonthlyRent"
            type="number"
            pattern="[0-9]"
            placeholder="Monatsmiete inkl. Nebenkosten"
            required
          />
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group
          :id="'input-group-25-'.concat(personIndex)"
          label="* Referenzperson mit Telefonnummer"
          :label-for="'input-25-'.concat(personIndex)"
        >
          <b-form-input
            :id="'input-25-'.concat(personIndex)"
            v-model="value.actualReferencePerson"
            type="text"
            placeholder="Referenzperson mit Telefonnummer"
            required
          />
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group
          :id="'input-group-26-'.concat(personIndex)"
          label="* Zimmerzahl"
          :label-for="'input-26-'.concat(personIndex)"
        >
          <b-form-select
              :id="'input-26-'.concat(personIndex)"
              v-model.number="value.actualRooms"
              required
          >
            <option value="" selected disabled>Bitte auswählen</option>
            <option value="1">1</option>
            <option value="1.5">1.5</option>
            <option value="2">2</option>
            <option value="2.5">2.5</option>
            <option value="3">3</option>
            <option value="3.5">3.5</option>
            <option value="4">4</option>
            <option value="4.5">4.5</option>
            <option value="5">5</option>
            <option value="5.5">5.5</option>
            <option value="6">6</option>
            <option value="6.5">6.5</option>
            <option value="7">mehr</option>
          </b-form-select>


        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group
          :id="'input-group-27-'.concat(personIndex)"
          label="* Begründung für den Wohnungswechsel"
          :label-for="'input-27-'.concat(personIndex)"
        >
          <b-form-textarea
            :id="'input-27-'.concat(personIndex)"
            v-model="value.moveReason"
            placeholder="Begründung für den Wohnungswechsel"
            required
          />
        </b-form-group>
      </b-col>
      </template>

      <b-col md="12">
        <h2>Zusätzliche Unterlagen</h2>
      </b-col>

      <b-col md="4">
        <b-form-group>
          <template slot="label"
            >* Betreibungsauskunft: <br />
            (nicht älter als 90 Tage)</template
          >
          <div class="upload">
            <div
              v-show="$refs.uploadBetreibung && $refs.uploadBetreibung.dropActive"
              class="drop-active"
            >
              <h3>Drop files to upload</h3>
            </div>

            <div class="">
              <file-upload
                class="upload-area mb-2"
                :class="{'files-added': value.betreibungsauskunftFile.length}"
                :multiple="false"
                :drop="true"
                :drop-directory="true"
                v-model="value.betreibungsauskunftFile"
                ref="uploadBetreibung"
                :input-id="'input-group-28-'.concat(personIndex)"
              >
                <ul v-if="value.betreibungsauskunftFile.length" class="file-list">
                  <li v-for="file in value.betreibungsauskunftFile" :key="file.id">
                     <span> <CheckSolid class="check" /> </span> <span>{{ file.name }}</span>
                  </li>
                </ul>
                <div v-else>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="87.631"
                    height="81.434"
                    viewBox="0 0 87.631 81.434"
                  >
                    <g
                      id="Group_237"
                      data-name="Group 237"
                      transform="translate(-6.323 -9.283)"
                    >
                      <path
                        id="Path_163"
                        data-name="Path 163"
                        d="M90.333,31,39,82.333c-5,5-10.333,7.667-15.667,8.334-5,.333-9.333-1-12.666-4.334-6-6-6.667-17.666,3.666-28.333L55.667,16.667a2.226,2.226,0,0,1,3.333,0A2.226,2.226,0,0,1,59,20L17.667,61.333C9,70,9.667,78.667,14,83a10.709,10.709,0,0,0,9,3c4.333-.333,8.667-2.667,12.667-6.667L87,27.667c3.667-3.667,2.333-8.334-.667-11a10.478,10.478,0,0,0-5-2.667,7.806,7.806,0,0,0-5.666,2.333L40.333,51.667C37,55,36.667,58,37.667,59.333,39,60.667,42,60,45.333,56.667l25-25A2.357,2.357,0,0,1,73.667,35L49,60c-6,6-11.667,5.333-14.333,2.667-3-3-3.334-8.667,2.666-14.334L72.333,13a11.6,11.6,0,0,1,9.334-3.667,14.008,14.008,0,0,1,8.333,4C94.333,17.667,96,25.333,90.333,31Z"
                        fill="#bebebe"
                      />
                    </g>
                  </svg>
                </div>
              </file-upload>

              <div>
                <div class="text-center remove-files" @click="removeBetreibung()" v-if="value.betreibungsauskunftFile.length">
                    Datei entfernen
                </div>
                <div v-else class="text-center" >
                    Klicken zum hinzufügen
                </div>
              </div>
            </div>
          </div>
        </b-form-group>
      </b-col>

      <b-col md="4"
         v-if="value.job == 'EMPLOYED' || value.job == 'SELF_EMPLOYED' || value.job == 'RETIRED'"
      >
        <b-form-group>
          <template slot="label"
            >* Lohnabrechnung: <br />
            (Steuerrechnung bei Selbstständigkeit / AHV-Abrechnung bei Rentner)</template
          >

          <div class="upload">
            <div
              v-show="$refs.uploadSalary && $refs.uploadSalary.dropActive"
              class="drop-active"
            >
              <h3>Drop files to upload</h3>
            </div>

            <div class="">
              <file-upload
                class="upload-area mb-2"
                :class="{'files-added': value.salaryCertificateFiles.length}"
                :multiple="false"
                :drop="true"
                :drop-directory="true"
                v-model="value.salaryCertificateFiles"
                ref="uploadSalary"
                :input-id="'input-group-29-'.concat(personIndex)"
              >
                <ul v-if="value.salaryCertificateFiles.length" class="file-list">
                  <li v-for="file in value.salaryCertificateFiles" :key="file.id">
                     <span> <CheckSolid class="check" /> </span> <span>{{ file.name }}</span>
                  </li>
                </ul>
                <div v-else>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="87.631"
                    height="81.434"
                    viewBox="0 0 87.631 81.434"
                  >
                    <g
                      id="Group_237"
                      data-name="Group 237"
                      transform="translate(-6.323 -9.283)"
                    >
                      <path
                        id="Path_163"
                        data-name="Path 163"
                        d="M90.333,31,39,82.333c-5,5-10.333,7.667-15.667,8.334-5,.333-9.333-1-12.666-4.334-6-6-6.667-17.666,3.666-28.333L55.667,16.667a2.226,2.226,0,0,1,3.333,0A2.226,2.226,0,0,1,59,20L17.667,61.333C9,70,9.667,78.667,14,83a10.709,10.709,0,0,0,9,3c4.333-.333,8.667-2.667,12.667-6.667L87,27.667c3.667-3.667,2.333-8.334-.667-11a10.478,10.478,0,0,0-5-2.667,7.806,7.806,0,0,0-5.666,2.333L40.333,51.667C37,55,36.667,58,37.667,59.333,39,60.667,42,60,45.333,56.667l25-25A2.357,2.357,0,0,1,73.667,35L49,60c-6,6-11.667,5.333-14.333,2.667-3-3-3.334-8.667,2.666-14.334L72.333,13a11.6,11.6,0,0,1,9.334-3.667,14.008,14.008,0,0,1,8.333,4C94.333,17.667,96,25.333,90.333,31Z"
                        fill="#bebebe"
                      />
                    </g>
                  </svg>
                </div>
              </file-upload>

              <div>
                <div class="text-center remove-files" @click="removeSalaryFiles()" v-if="value.salaryCertificateFiles.length">
                    Datei entfernen
                </div>
                <div v-else class="text-center" >
                    Klicken zum hinzufügen
                </div>
              </div>
            </div>
          </div>
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group
          label="* Identitätskarte, Schweizerpass oder Ausländerausweis:"
        >
          <div class="upload">
            <div
              v-show="$refs.uploadID && $refs.uploadID.dropActive"
              class="drop-active"
            >
              <h3>Drop files to upload</h3>
            </div>

            <div class="">
              <file-upload
                class="upload-area mb-2"
                :class="{'files-added': value.identificationFile.length}"
                :multiple="false"
                :drop="true"
                :drop-directory="true"
                v-model="value.identificationFile"
                ref="uploadID"
                :input-id="'input-group-30-'.concat(personIndex)"
              >
                <ul v-if="value.identificationFile.length" class="file-list">
                  <li v-for="file in value.identificationFile" :key="file.id">
                     <span> <CheckSolid class="check" /> </span> <span>{{ file.name }}</span>
                  </li>
                </ul>
                <div v-else>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="87.631"
                    height="81.434"
                    viewBox="0 0 87.631 81.434"
                  >
                    <g
                      id="Group_237"
                      data-name="Group 237"
                      transform="translate(-6.323 -9.283)"
                    >
                      <path
                        id="Path_163"
                        data-name="Path 163"
                        d="M90.333,31,39,82.333c-5,5-10.333,7.667-15.667,8.334-5,.333-9.333-1-12.666-4.334-6-6-6.667-17.666,3.666-28.333L55.667,16.667a2.226,2.226,0,0,1,3.333,0A2.226,2.226,0,0,1,59,20L17.667,61.333C9,70,9.667,78.667,14,83a10.709,10.709,0,0,0,9,3c4.333-.333,8.667-2.667,12.667-6.667L87,27.667c3.667-3.667,2.333-8.334-.667-11a10.478,10.478,0,0,0-5-2.667,7.806,7.806,0,0,0-5.666,2.333L40.333,51.667C37,55,36.667,58,37.667,59.333,39,60.667,42,60,45.333,56.667l25-25A2.357,2.357,0,0,1,73.667,35L49,60c-6,6-11.667,5.333-14.333,2.667-3-3-3.334-8.667,2.666-14.334L72.333,13a11.6,11.6,0,0,1,9.334-3.667,14.008,14.008,0,0,1,8.333,4C94.333,17.667,96,25.333,90.333,31Z"
                        fill="#bebebe"
                      />
                    </g>
                  </svg>
                </div>
              </file-upload>

              <div>
                <div class="text-center remove-files" @click="removeIDFiles()" v-if="value.identificationFile.length">
                    Datei entfernen
                </div>
                <div v-else class="text-center" >
                    Klicken zum hinzufügen
                </div>
              </div>
            </div>
          </div>
        </b-form-group>
      </b-col>

      <b-col> </b-col>
    </b-row>

    <b-form-group
      :id="'input-group-33-'.concat(personIndex)"
      label="* Privathaftpflicht vorhanden?"
      :label-for="'input-33-'.concat(personIndex)"
    >
      <b-checkbox
        :id="'input-33-'.concat(personIndex)"
        v-model="value.privathaftpflicht"
        type="checkbox"
      />
    </b-form-group>

    <b-form-group
      v-if="value.job == 'EMPLOYED'"
      :id="'input-group-34-'.concat(personIndex)"
      label="Referenz bei Vorgesetzten einholen?"
      :label-for="'input-34-'.concat(personIndex)"
    >
      <b-checkbox
        :id="'input-34-'.concat(personIndex)"
        v-model="value.referenceFromSupervisor"
        required
      />
    </b-form-group>

    <b-form-group
      :id="'input-group-35-'.concat(personIndex)"
      label="* Referenz bei Verwaltung einholen?"
      :label-for="'input-35-'.concat(personIndex)"
    >
      <b-checkbox
        :id="'input-35-'.concat(personIndex)"
        v-model="value.referenceFromAdministration"
        name="referenceFromAdmin"
        required
      />
    </b-form-group>
  </div>
</template>

<script>
import { cloneDeep, tap, set } from "lodash";
import CheckSolid from '~/assets/icons/check-solid.svg?inline';

export default {
  props: {
    isFirstPerson: {
      type: Boolean,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    personIndex: {
      type: Number,
      required: true,
    },
  },
  mounted: function() {

    /*
    const fileInputs = document.querySelectorAll("input[type=file]");

    fileInputs.forEach((input) => {
      input.required = true;
    }); */
  },
  data() {
    return {
      show: true,
      salaryFiles: [],
      nationalities: [
        'Andorra',
        'Vereinigte Arabische Emirate',
        'Afghanistan',
        'Antigua und Barbuda',
        'Anguilla',
        'Albanien',
        'Armenien',
        'Angola',
        'Antarktis',
        'Argentinien',
        'Amerikanisch-Samoa',
        'Österreich',
        'Australien',
        'Aruba',
        'Åland',
        'Aserbaidschan',
        'Bosnien und Herzegowina',
        'Barbados',
        'Bangladesch',
        'Belgien',
        'Burkina Faso',
        'Bulgarien',
        'Bahrain',
        'Burundi',
        'Benin',
        'Saint Barthélemy',
        'Bermuda',
        'Brunei Darussalam',
        'Bolivien (Plurinationaler Staat)',
        'Bonaire, Sint Eustatius und Saba',
        'Brasilien',
        'Bahamas',
        'Bhutan',
        'Bouvetinsel',
        'Botswana',
        'Weissrussland',
        'Belize',
        'Kanada',
        'Kokos-Inseln',
        'Demokratische Republik Kongo',
        'Zentralafrikanische Republik',
        'Republik Kongo',
        'Schweiz',
        'Elfenbeinküste',
        'Cookinseln',
        'Chile',
        'Kamerun',
        'China',
        'Kolumbien',
        'Costa Rica',
        'Kuba',
        'Kapverdische Inseln',
        'Curaçao',
        'Weihnachtsinsel',
        'Zypern',
        'Tschechien',
        'Deutschland',
        'Dschibuti',
        'Dänemark',
        'Dominica',
        'Dominikanische Republik',
        'Algerien',
        'Ecuador',
        'Estland',
        'Ägypten',
        'Westsahara',
        'Eritrea',
        'Spanien',
        'Äthiopien',
        'Finnland',
        'Fidschi',
        'Falkland Inseln [Malwinen]',
        'Föderierte Staaten von Mikronesien',
        'Färöer-Inseln',
        'Frankreich',
        'Gabun',
        'Grossbritannien',
        'Grenada',
        'Georgien',
        'Französisch-Guayana',
        'Guernsey',
        'Ghana',
        'Gibraltar',
        'Grönland',
        'Gambia',
        'Guinea',
        'Guadeloupe',
        'Äquatorialguinea',
        'Griechenland',
        'Südgeorgien und die Südlichen Sandwichinseln',
        'Guatemala',
        'Guam',
        'Guinea-Bissau',
        'Guyana',
        'Hongkong',
        'Heard und McDonaldinseln',
        'Honduras',
        'Kroatien',
        'Haiti',
        'Ungarn',
        'Indonesien',
        'Irland',
        'Israel',
        'Insel Man',
        'Indien',
        'Britisches Territorium im Indischen Ozean',
        'Irak',
        'Iran (Islamische Republik)',
        'Island',
        'Italien',
        'Jersey',
        'Jamaika',
        'Jordanien',
        'Japan',
        'Kenia',
        'Kirgisistan',
        'Kambodscha',
        'Kiribati',
        'Komoren',
        'St. Kitts und Nevis',
        'Korea (Demokratische Volksrepublik)',
        'Republik Korea (Südkorea)',
        'Kuwait',
        'Kaimaninseln',
        'Kasachstan',
        'Demokratische Volksrepublik Laos',
        'Libanon',
        'St. Lucia',
        'Liechtenstein',
        'Sri Lanka',
        'Liberia',
        'Lesotho',
        'Litauen',
        'Luxemburg',
        'Lettland',
        'Libyen',
        'Marokko',
        'Monaco',
        'Republik Moldau',
        'Montenegro',
        'Saint-Martin (franz. Teil)',
        'Madagaskar',
        'Marshallinseln',
        'Nordmazedonien',
        'Mali',
        'Myanmar',
        'Mongolei',
        'Macao',
        'Nördliche Marianen',
        'Martinique',
        'Mauretanien',
        'Montserrat',
        'Malta',
        'Mauritius',
        'Malediven',
        'Malawi',
        'Mexiko',
        'Malaysia',
        'Mosambik',
        'Namibia',
        'Neukaledonien',
        'Niger',
        'Norfolkinsel',
        'Nigeria',
        'Nicaragua',
        'Niederlande',
        'Norwegen',
        'Nepal',
        'Nauru',
        'Niue',
        'Neuseeland',
        'Oman',
        'Panama',
        'Peru',
        'Französisch-Polynesien',
        'Papua-Neuguinea',
        'Philippinen',
        'Pakistan',
        'Polen',
        'Saint Pierre und Miquelon',
        'Pitcairninseln',
        'Puerto Rico',
        'Staat Palästina',
        'Portugal',
        'Palau',
        'Paraguay',
        'Katar',
        'Réunion',
        'Rumänien',
        'Serbien',
        'Russische Föderation',
        'Rwanda',
        'Saudi-Arabien',
        'Salomonen',
        'Seychellen',
        'Sudan',
        'Schweden',
        'Singapur',
        'St. Helena, Ascension und Tristan da Cunha',
        'Slowenien',
        'Svalbard und Jan Mayen',
        'Slowakei',
        'Sierra Leone',
        'San Marino',
        'Senegal',
        'Somalia',
        'Suriname',
        'Südsudan',
        'São Tomé und Príncipe',
        'El Salvador',
        'Sint Maarten (niederl. Teil)',
        'Arabische Republik Syrien',
        'Eswatini',
        'Turks und Caicos',
        'Tschad',
        'Französische Süd- und Antarktisgebiete',
        'Togo',
        'Thailand',
        'Tadschikistan',
        'Tokelau',
        'Osttimor',
        'Turkmenistan',
        'Tunesien',
        'Tonga',
        'Türkei',
        'Trinidad und Tobago',
        'Tuvalu',
        'Republik China (Taiwan)',
        'Vereinigte Republik Tansania',
        'Ukraine',
        'Uganda',
        'United States Minor Outlying Islands',
        'Vereinigte Staaten von Amerika',
        'Uruguay',
        'Usbekistan',
        'Vatikanstadt',
        'St. Vincent und die Grenadinen',
        'Venezuela (Bolivarische Republik)',
        'Britische Jungferninseln',
        'Amerikanische Jungferninseln',
        'Vietnam',
        'Vanuatu',
        'Wallis und Futuna',
        'Samoa',
        'Republik Kosovo',
        'Unbekannt',
        'Staatenlos',
        'Jemen',
        'Mayotte',
        'Südafrika',
        'Sambia',
        'Simbabwe'
      ]
    };
  },
  methods: {
    update(key, value) {
      this.$emit(
        "input",
        tap(cloneDeep(this.value), (v) => set(v, key, value))
      );
    },
    removeSalaryFiles() {
      this.$refs.uploadSalary.clear();
    },
    removeBetreibung() {
      this.$refs.uploadBetreibung.clear();
    },
    removeIDFiles() {
      this.$refs.uploadID.clear();
    },
    copyApartment(checked) {
      this.$emit('copy-apartment', checked, this.personIndex);
    },
  },
  components: {
    CheckSolid,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_vars.scss";

.form-person {
  input,
  textarea {
    background-color: #f0f0f0;
    border: none;
  }

  .custom-select {
    background-color: #f0f0f0;
    border: none;
  }

  .row > div {
    margin-bottom: 1rem;
  }

  .form-group {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
  }
}

.upload {
}

.upload-area {
  background-color: $dark-white;
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  /deep/ label {
    cursor: pointer;
  }

  &:hover {
    background-color: $light-gray;
  }
}

.files-added {
  background-color: $light-brown;
}

.check {
  width: 20px;
  margin-right: 5px;
}

.file-list {
  list-style: none;
  margin: 0;
  padding: 0;
  color: white;
}

.remove-files {
  cursor: pointer;
}

</style>
