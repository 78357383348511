<template>
  <div class="">
    <div :class="{ 'sticky-top': with3d }" class="filters-container">
      <div
        class="filters"
        v-click-outside="hideFilterDropdowns"
        :style="{ 'background-color': bgColor }"
      >
        <div class="main-container">
          <div class="filter-bar">
            <input
              class="form-control"
              :class="{ active: currentDropdown === 'costs' }"
              readonly
              @click="toggleDropdown('costs')"
              :value="`Mietzins: CHF ${filterData.costs[0]} - ${filterData.costs[1]}.-`"
            />
            <input
              class="form-control"
              :class="{ active: currentDropdown === 'rooms' }"
              readonly
              :value="`Zimmer: ${filterData.rooms[0]} - ${filterData.rooms[1]}`"
              @click="toggleDropdown('rooms')"
            />
            <input
              class="form-control"
              :class="{ active: currentDropdown === 'space' }"
              readonly
              :value="`Fläche: ${filterData.space[0]} - ${filterData.space[1]}m²`"
              @click="toggleDropdown('space')"
            />

            <v-select
              class="status-input"
              :options="getFloorOptions()"
              v-model="filterData.floor"
              placeholder="Geschoss"
            />

            <v-select
              class="status-input"
              :options="getStatusOptions()"
              v-model="filterData.state"
              placeholder="Status"
            />

            <div class="status-input status-checkbox">
              <b-checkbox v-model="filterData.ageAppropriate">
                Alterswohnungen
              </b-checkbox>
            </div>

            <div class="warning-text">
              <div class="color-box"></div>
              <p>
                Bei den markierten Wohnungen handelt es sich um Alterswohnungen
              </p>
            </div>
          </div>
        </div>
        <div class="filter-dropdown-container">
          <div
            class="filter-dropdown"
            :class="{ visible: currentDropdown === 'costs' }"
            :style="{ 'background-color': bgColor }"
          >
            <div class="vue-range-slider-container">
              <no-ssr>
                <vue-range-slider
                  ref="rangeSliderCosts"
                  v-if="costsMin !== null"
                  v-model.lazy="filterData.costs"
                  :lazy="true"
                  :min="costsMin"
                  :max="costsMax"
                  :enable-cross="true"
                  :debug="false"
                  formatter="CHF {value}.-"
                  tooltip-dir="top"
                  class="range-slider"
                />
              </no-ssr>
            </div>
          </div>
          <div
            class="filter-dropdown"
            :class="{ visible: currentDropdown === 'rooms' }"
            :style="{ 'background-color': bgColor }"
          >
            <div class="vue-range-slider-container">
              <no-ssr>
                <vue-range-slider
                  ref="rangeSliderRooms"
                  v-if="roomsMin !== null"
                  v-model.lazy="filterData.rooms"
                  :lazy="true"
                  :min="roomsMin"
                  :max="roomsMax"
                  :step="0.5"
                  :enable-cross="true"
                  :debug="false"
                  formatter="{value} Zi."
                  tooltip-dir="top"
                  class="range-slider"
                />
              </no-ssr>
            </div>
          </div>
          <div
            class="filter-dropdown"
            :class="{ visible: currentDropdown === 'space' }"
            :style="{ 'background-color': bgColor }"
          >
            <div class="vue-range-slider-container">
              <no-ssr>
                <vue-range-slider
                  ref="rangeSliderSpace"
                  v-if="spaceMin !== null"
                  v-model.lazy="filterData.space"
                  :lazy="true"
                  :min="spaceMin"
                  :max="spaceMax"
                  :enable-cross="true"
                  :debug="false"
                  formatter="{value} m²"
                  tooltip-dir="top"
                  class="range-slider"
                />
              </no-ssr>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid averta-regular">
        <div
          class="row apartment-table-header"
          :style="{ 'background-color': bgColor }"
        >
          <div class="col-12" :class="{ 'col-md-6 offset-md-6': with3d }">
            <table class="apartment-table d-none d-md-table">
              <thead>
                <tr>
                  <th>Typ</th>
                  <th>Etage</th>
                  <th>Zi.</th>
                  <th>m<sup>2</sup></th>
                  <th>Miete + NK</th>
                  <th>Anteils&shy;scheine</th>
                  <th>Status</th>
                  <th />
                </tr>
              </thead>
              <tbody />
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="configurator">
        <div class="row">
          <div class="col-12 col-md-6 order-1 order-md-0" v-if="with3d">
            <div class="w-100 position-relative scene-wrapper">
              <div ref="scene" class="scene" />
            </div>
          </div>
          <div class="col-12" :class="{ 'col-md-6': with3d }">
            <div class="list">
              <div class="table-wrapper">
                <table class="apartment-table">
                  <thead>
                    <tr class="d-table-row d-md-none">
                      <th>Typ</th>
                      <th>Etage</th>
                      <th>Zi.</th>
                      <th>m<sup>2</sup></th>
                      <th>Miete + NK</th>
                      <th>Status</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      :id="`tr-${apt.apartmentnumber}`"
                      v-for="apt in listData"
                      :key="apt.apartmentnumber"
                      :class="{
                        'age-appropriate': apt.ageAppropriate,
                        'tr-selected':
                          selectApartmentNumber === apt.apartmentnumber,
                        'apt-occupied': apt.state !== 'free',
                      }"
                      @click="select(apt.apartmentnumber)"
                    >
                      <td>
                        {{ apt.apartmentnumber }}
                        <span title="Alterswohnung" v-if="apt.ageAppropriate"
                          >(AW)</span
                        >
                        <span class="d-md-none">
                          <br />
                          {{ getFloor(apt.floor) }}<br />
                          {{ apt.apartmentTemplate.rooms }} Zi. ({{
                            apt.apartmentTemplate.space
                          }}m<sup>2</sup>)
                        </span>
                      </td>
                      <td>{{ getFloor(apt.floor) }}</td>
                      <td>{{ apt.apartmentTemplate.rooms }}</td>
                      <td>{{ apt.apartmentTemplate.space }}m<sup>2</sup></td>
                      <td>
                        {{ apt.costs / 100 }} + {{ apt.additionalCosts / 100 }}
                      </td>
                      <td>
                        {{ apt.depot / 100 }}
                      </td>
                      <td>{{ getStatus(apt.state) }}</td>
                      <td>
                        <div v-if="apt.state == 'free'">
                          <a
                            v-if="choiceMode === false"
                            target="_blank"
                            :href="
                              localePath({
                                name: 'apartments-apartment',
                                params: { apartment: apt.id },
                              })
                            "
                            class="btn btn-primary"
                          >
                            Details
                          </a>
                          <a
                            href="#"
                            v-else
                            class="btn btn-primary"
                            @click.prevent.stop="$emit('chosen', apt)"
                          >
                            Auswählen
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="d-flex justify-content-between">
                  <div>
                    {{ apartmentPageStart }} - {{ apartmentPageEnd }} von
                    {{ totalApartments }} Wohnungen
                  </div>
                  <b-pagination-nav
                    v-model="currentPage"
                    :number-of-pages="numPages"
                    base-url="#"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Scene from "../js/Scene";
import { getStatus, getFloor } from "../../utils/translation";

const selected = null;

export default {
  components: {
    VueRangeSlider: () =>
      process.client ? import("vue-range-component") : null,
  },
  props: {
    payload: {
      type: Object,
      default: Object,
    },
    with3d: {
      type: Boolean,
      default: true,
    },
    bgColor: {
      type: String,
      default: "#FFF",
    },
    choiceMode: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["chosen"],
  data() {
    return {
      selectApartmentNumber: null,
      apartments: {},
      costsMin: null,
      costsMax: null,
      spaceMin: null,
      spaceMax: null,
      roomsMin: null,
      roomsMax: null,
      filterData: {
        costs: [null, null],
        rooms: [null, null],
        space: [null, null],
        floor: [],
        state: [],
        ageAppropriate: null,
      },
      currentPage: 1,
      pageSize: 20,
      currentDropdown: null,
    };
  },
  computed: {
    listData() {
      return Object.values(this.apartments).slice(
        (this.currentPage - 1) * this.pageSize,
        this.currentPage * this.pageSize
      );
    },
    numPages() {
      return Math.max(
        1,
        Math.ceil(Object.values(this.apartments).length / this.pageSize)
      );
    },
    apartmentPageStart() {
      return this.totalApartments === 0
        ? 0
        : (this.currentPage - 1) * this.pageSize + 1;
    },
    apartmentPageEnd() {
      return Math.min(
        this.totalApartments,
        (this.currentPage - 1) * this.pageSize + this.pageSize
      );
    },
    totalApartments() {
      return Object.values(this.apartments).length;
    },
    rentBackendUrl() {
      return this.$config.rentBackendUrl;
    },
  },
  watch: {
    filterData: {
      deep: true,
      async handler() {
        await this.getResults();
        this.currentPage = 1;
        if (this.scene) {
          this.scene.setApartments(this.apartments);
          this.scene.colorize();
        }
      },
    },
  },
  async fetch() {
    await Promise.all([this.getLimits(), this.getResults()]);
  },
  mounted() {
    if (this.with3d) {
      this.scene = new Scene(this.$refs.scene, this.$root, this.apartments);

      this.scene.loadModel("/model/with_text.glb");

      this.$root.$on("selectObject", (id) => {
        this.select(id);
      });
    }
  },
  methods: {
    hideFilterDropdowns() {
      this.currentDropdown = null;
    },
    toggleDropdown(dropdown) {
      if (dropdown === this.currentDropdown) {
        // Clicked on already visible
        this.currentDropdown = null;
      } else if (this.currentDropdown === null) {
        // Nothing visible yet
        this.currentDropdown = dropdown;
      } else {
        // Switch to other
        this.currentDropdown = null;
        window.setTimeout(() => {
          this.currentDropdown = dropdown;
        }, 250);
      }
    },
    getStatusOptions() {
      return [
        {
          label: "Frei",
          code: "free",
        },
        {
          label: "Vermietet",
          code: "occupied",
        },
        {
          label: "Reserviert",
          code: "reserved",
        },
      ];
    },
    getFloorOptions() {
      return [
        {
          label: "EG",
          code: "0",
        },
        {
          label: "1. OG",
          code: "1",
        },
        {
          label: "2. OG",
          code: "2",
        },
        {
          label: "3. OG",
          code: "3",
        },
        {
          label: "4. OG",
          code: "4",
        },
        {
          label: "5. OG",
          code: "5",
        },
      ];
    },
    getStatus,
    getFloor,
    async getLimits() {
      const result = (
        await this.$axios.get(`${this.rentBackendUrl}apartments/filterData`)
      ).data[0];
      this.filterData.rooms[0] = parseFloat(result.minRooms);
      this.filterData.rooms[1] = parseFloat(result.maxRooms);
      this.roomsMin = parseFloat(result.minRooms);
      this.roomsMax = parseFloat(result.maxRooms);
      this.filterData.space[0] = parseFloat(result.minSpace);
      this.filterData.space[1] = parseFloat(result.maxSpace);
      this.spaceMin = parseFloat(result.minSpace);
      this.spaceMax = parseFloat(result.maxSpace);
      this.filterData.costs[0] = parseFloat(result.minCosts / 100);
      this.filterData.costs[1] = parseFloat(result.maxCosts / 100);
      this.costsMin = parseFloat(result.minCosts / 100);
      this.costsMax = parseFloat(result.maxCosts / 100);
    },
    async getResults() {
      const params = {
        page: this.currentPage,
        itemsPerPage: 20,
        hidden: false,
      };
      if (this.filterData.costs) {
        if (this.filterData.costs[0] !== null) {
          params["costs[gte]"] = this.filterData.costs[0] * 100;
        }
        if (this.filterData.costs[1] !== null) {
          params["costs[lte]"] = this.filterData.costs[1] * 100;
        }
      }
      if (this.filterData.rooms) {
        if (this.filterData.rooms[0] !== null) {
          params["apartmentTemplate.rooms[gte]"] = this.filterData.rooms[0];
        }
        if (this.filterData.rooms[1] !== null) {
          params["apartmentTemplate.rooms[lte]"] = this.filterData.rooms[1];
        }
      }
      if (this.filterData.space) {
        if (this.filterData.space[0] !== null) {
          params["apartmentTemplate.space[gte]"] = this.filterData.space[0];
        }
        if (this.filterData.space[1] !== null) {
          params["apartmentTemplate.space[lte]"] = this.filterData.space[1];
        }
      }

      if (this.filterData.state && this.filterData.state.code) {
        const selectedVal = this.filterData.state.code;
        params.state = selectedVal;
      }

      if (this.filterData.floor && this.filterData.floor.code) {
        const selectedVal = this.filterData.floor.code;
        params.floor = selectedVal;
      }
      if (this.filterData.ageAppropriate) {
        params.ageAppropriate = "1";
      }

      const result = await this.$axios.get(
        `${this.rentBackendUrl}apartments?pagination=false`,
        {
          params,
        }
      );
      const apartments = result.data["hydra:member"];
      const apartmentMap = {};
      apartments.forEach((apartment) => {
        apartmentMap[apartment.apartmentnumber] = apartment;
      });
      this.apartments = apartmentMap;
    },
    select(id) {
      this.selectApartmentNumber = id;
      if (this.with3d) {
        this.scene.select(id);
      }

      // Get proper page offset
      for (let i = 0; i < Object.values(this.apartments).length; i++) {
        if (Object.values(this.apartments)[i].apartmentnumber == id) {
          this.currentPage = Math.ceil((i + 1) / 20);
        }
      }
    },
    refreshRangeInputs() {
      this.$refs.rangeSliderCosts.refresh();
      this.$refs.rangeSliderRooms.refresh();
      this.$refs.rangeSliderSpace.refresh();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_vars.scss";
@import "~bootstrap/scss/mixins";

.active {
  border-color: $medium-brown !important;
  color: $medium-brown !important;
}

p {
  font-family: Averta-Regular;
}

.filters {
  @include media-breakpoint-up(sm) {
  }

  .filter-bar {
    @media screen and (min-width: 1400px) {
      justify-content: center;
    }

    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
    padding-top: 20px;
    padding-bottom: 20px;

    input {
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
      width: auto;
      background-color: transparent;
      border: 3px solid #e2e2e2;
      color: #bebebe;
      &:focus {
        box-shadow: none;
      }
    }
  }
  .filter-dropdown-container {
    position: relative;
    .filter-dropdown {
      position: absolute;
      left: 0;
      right: 0;
      height: 0;
      overflow: hidden;
      transition: height 0.25s ease-in-out 0s;
      z-index: 10;
      display: flex;
      align-items: center;
      .vue-range-slider-container {
        width: 100%;
        margin: 0 auto;
        max-width: 400px;
      }

      &.visible {
        height: 100px;
      }
    }
  }
}

.configurator {
  width: 100%;
  min-height: 800px;
  position: relative;
}

.range-slider {
  /deep/ .slider-tooltip {
    background-color: #81524c !important;
    border-color: #81524c !important;
  }

  /deep/ .slider-process {
    background-color: #81524c !important;
  }

  /deep/ .slider-dot-handle {
    background-color: #81524c !important;
    border-radius: 2px !important;
    box-shadow: unset !important;
  }

  /deep/ .slider {
    height: 3px !important;
  }

  /deep/ .slider-dot {
    top: -6px !important;
  }
}

.scene-wrapper {
  @include media-breakpoint-down(sm) {
    height: 350px;
    overflow: auto;
  }
}

.filters-container {
  @include media-breakpoint-down(sm) {
    position: static;
  }
}

.scene {
  position: relative;
  width: 100%;
  height: 400px;

  @include media-breakpoint-up(sm) {
    height: 500px;
  }

  @include media-breakpoint-up(md) {
    height: 800px;
  }
}

.apartment-table-header {
  tr {
    cursor: auto;
  }
  th {
    font-family: Averta-Bold;
  }

  .apartment-table {
    margin-bottom: 0;
  }
}

.apt-occupied {
  color: #bebebe;
}

tr {
  cursor: pointer;
  margin: 20px;
  border-radius: 5px;
}

td {
  z-index: 2;
  .btn-primary {
    width: auto;
    height: auto;
  }
}

.tr-selected {
  position: relative;
  z-index: 2;
  background: #f8bf4f;
  border-radius: 5px;
}

.status-input {
  background-color: transparent;
  border: 3px solid #e2e2e2;
  color: #bebebe;
  border-radius: 0.25rem;

  &.status-checkbox {
    height: 2.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
  }

  @include media-breakpoint-up(sm) {
    width: initial;
  }

  width: 100%;

  &.vs--open {
    border-color: $medium-brown;

    /deep/ .vs__search {
      color: $medium-brown;
    }
  }

  /deep/ .vs__search {
    color: #bebebe;
  }

  /deep/ .vs__dropdown-toggle {
    border: none;

    @include media-breakpoint-up(sm) {
      width: 200px;
    }
  }
}

/*
    .tr-selected:after{
      width: 100%;
      height: 100%;
      content: '';
      background: #f8bf4f;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      border-radius: 5px;
    }*/

.list {
}

.table-wrapper {
  position: relative;
}

.warning-text {
  display: flex;
  align-items: center;

  .color-box {
    background: rgba(237, 228, 231, 1);
    height: 40px;
    width: 40px;
    margin-right: 15px;
  }

  p {
    margin: 0;
  }
}

.age-appropriate {
  background: rgba(237, 228, 231, 1);
}

.apartment-table {
  width: 100%;
  position: relative;
  margin-bottom: 40px;
  //border-collapse: collapse;
  tr {
    border-bottom: 1px solid #ccc;

    &:hover {
      background-color: #5f3641;
      color: white;
    }

    td,
    th {
      &:nth-child(1) {
        width: 10%;
        @media (max-width: 768px) {
          width: auto;
        }
      }

      &:nth-child(2) {
        width: 10%;
        @media (max-width: 768px) {
          display: none;
        }
      }

      &:nth-child(3) {
        width: 15%;
        @media (max-width: 768px) {
          display: none;
        }
      }

      &:nth-child(4) {
        width: 15%;
        @media (max-width: 768px) {
          display: none;
        }
      }

      &:nth-child(5) {
        width: 15%;
      }

      &:nth-child(6) {
        width: 15%;
      }

      &:nth-child(7) {
        width: 100px;
      }
      &:nth-child(8) {
        width: 100px;
      }
    }
  }
}

ul {
  margin-top: 30px;
  text-align: left;
}

@media (max-width: 970px) {
  .list {
    position: relative;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
